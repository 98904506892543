import * as React from "react";
import { Link, PageProps } from "gatsby";
import * as styles from "./styles.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import classNames from "classnames";
import { Breadcrumb } from "../../../components/breadcrumb/breadcrumb";
import Layout from "../../../components/layout/layout";
import Container from "../../../components/container/container";
import Heading from "../../../components/basic-components/heading/heading";
import { ProductBar } from "../../../components/product-bar/product-bar";
import { Level } from "react-accessible-headings";
import scrollTo from "gatsby-plugin-smoothscroll";
import { Helmet } from "react-helmet";
import blogImage from "../../../images/kuru-nasil.jpg";
import { useEffect } from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const CoughHowPage: React.FC<PageProps> = ({ location }: PageProps) => {
  useEffect(() => {
    if (location.hash) {
      scrollTo(location.hash);
    }
  }, []);
  return (
    <Layout
      stickyHeader={false}
      seoConfig={{
        title: "Kuru Öksürük Nasıl Geçer, Ne İyi Gelir?",
        description:
          "Kuru öksürük nasıl geçer, kuru öksürüğe ne iyi gelir? Kuru öksürüğünüze nelerin iyi geleceğini öğrenmek için sayfamızı ziyaret edin!",
      }}
    >
      <Helmet>
        <script type="application/ld+json">
          {`
            {
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {"@type": "ListItem","position": 1,"name": "Ana Sayfa","item": "https://www.bisolnatur.com.tr/"},
                  {"@type": "ListItem","position": 2,"name": "Öksürük","item": "https://www.bisolnatur.com.tr/oksuruk/"},
                  {"@type": "ListItem","position": 3,"name": "Kuru Öksürük Nasıl Geçer, Ne İyi Gelir?","item": "${location.href}"}
                ]
            }
          `}
        </script>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "BlogPosting",
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "${location.href}"
              },
              "headline": "Kuru Öksürük Nasıl Geçer, Ne İyi Gelir?",
              "image": "${location.origin + blogImage}",  
              "author": {
                "@type": "Organization",
                "name": "Bisolnatur",
                "url": "${location.origin}"
              },  
              "publisher": {
                "@type": "Organization",
                "name": "Bisolnatur",
                "logo": {
                  "@type": "ImageObject",
                  "url": "${location.origin}/logo.svg"
                }
              },
              "datePublished": "2021-09-01",
              "dateModified": "2021-09-01"
            }
          `}
        </script>
        <script type="application/ld+json">{`{"@context":"https://schema.org","@type":"FAQPage","mainEntity":[{"@type":"Question","name":"Kuru Öksürük Nedenleri Nelerdir?","acceptedAnswer":{"@type":"Answer","text":"Kuru öksürüğün pek çok nedeni vardır. Bunlardan en yaygın olanı, soğuk algınlığı gibi üst solunum yolu enfeksiyonlarıdır."}},{"@type":"Question","name":"Kuru Öksürük Nasıl Geçer?","acceptedAnswer":{"@type":"Answer","text":"Kuru öksürüğün verdiği rahatsızlığı gidermek ve boğazı rahatlatmak için bal tüketilebilir. Bal tüketmek, boğaz ağrısını gidermek için tercih edilen, geleneksel bir yöntemdir."}},{"@type":"Question","name":"Bisolnatur Nasıl Kullanılır?","acceptedAnswer":{"@type":"Answer","text":"Bisolnatur’u kullanmadan önce kullanım kılavuzu dikkatle okunmalıdır. Doğru şekilde kullanım için, şişe açılmadan önce iyice çalkalanmalı ve kutu ile birlikte verilen kaşık kullanılmalıdır.\nHamile ve emzirenler doktora danışarak Bisolnatur’ü kullanabilir."}}]}`}</script>
      </Helmet>
      <Breadcrumb
        pathname={location.pathname}
        noBg={true}
        className={styles.breadcrumb}
      />
      <Container fullWidth>
        <div className={classNames(styles.header)}>
          <StaticImage
            className={styles.image}
            src="../../../images/kuru-nasil.jpg"
            alt="Hapşuran çocuk görseli"
            width={1680}
            height={450}
          />
          <div className={styles.filter} />
          <Container>
            <Heading>Kuru Öksürük Nasıl Geçer, Ne İyi Gelir?</Heading>
            <ul>
              <li>
                <AnchorLink
                  className="blue"
                  to="#kuru-oksuruk-nedir"
                  title="Kuru Öksürük Nedir?"
                >
                  Kuru Öksürük Nedir?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  className="blue"
                  to="#kuru-oksuruk-nedenleri-nelerdir"
                  title="Kuru Öksürük Nedenleri Nelerdir?"
                >
                  Kuru Öksürük Nedenleri Nelerdir?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  className="blue"
                  to="#kuru-oksuruk-nasil-gecer"
                  title="Kuru Öksürük Nasıl Geçer?"
                >
                  Kuru Öksürük Nasıl Geçer?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  className="blue"
                  to="#bisolnatur"
                  title="Bisolnatur"
                >
                  Bisolnatur
                </AnchorLink>
              </li>
              <li>
                <AnchorLink className="blue" to="#kaynakca" title="Kaynakça">
                  Kaynakça
                </AnchorLink>
              </li>
            </ul>
          </Container>
        </div>
      </Container>
      <Container>
        <div className={styles.holder}>
          <p className="extra-bold">
            Normal şartlarda öksürük, son derece doğal bir reflekstir. Vücut;
            öksürük yoluyla solunum yolundaki yabancı ve/veya zararlı
            partikülleri ya da mikropları uzaklaştırır. Bununla birlikte
            öksürük, alerji, viral bir enfeksiyonun veya pek çok şeyin habercisi
            olabilir. Öksürük kuru ya da balgamlı olabilir.
          </p>
          <StaticImage
            className={styles.image}
            src="../../../images/sickness-cough-coughing-sick-day-sick-child-catch-a-cold-common-cold-getting-sick-coronavirus_t20_2WOz9Z@3x.jpg"
            alt="Hapşuran çocuk görseli"
            width={792}
            height={348}
          />
          <section className={styles.whatHolder}>
            <Level>
              <Heading id="kuru-oksuruk-nedir" className="orange underline">
                Kuru Öksürük Nedir?
              </Heading>
              <p>
                <Link
                  className="blue underline"
                  to={"/oksuruk/kuru-oksuruk-nedir/"}
                >
                  Kuru öksürük,
                </Link>{" "}
                mukus (balgam) üretmeyen öksürüğü ifade eder.{" "}
                <Link className="blue underline" to={"/oksuruk/oksuruk-nedir/"}>
                  Öksürük,
                </Link>{" "}
                solunum yollarını tahriş edici maddelerden arındırmaya yarayan
                bir savunma mekanizmasıdır.
              </p>
              <Heading
                id="kuru-oksuruk-nedenleri-nelerdir"
                className="orange underline"
              >
                Kuru Öksürük Nedenleri Nelerdir?
              </Heading>
              <p>
                Kuru öksürüğün pek çok nedeni vardır. Bunlardan en yaygın olanı,
                soğuk algınlığı gibi üst solunum yolu enfeksiyonlarıdır.
              </p>
              <Heading
                id="kuru-oksuruk-nasil-gecer"
                className="orange underline"
              >
                Kuru Öksürük Nasıl Geçer?
              </Heading>
              <p>
                Kuru öksürüğün giderilmesi bazı durumlarda zor olabilir. Solunum
                yolları aşırı duyarlı hâle geldiğinde, öksürme sebebiyle boğaz
                kolayca tahriş olur ve öksürüğün daha da şiddetlenmesine neden
                olabilir.
                <br />
                <br />
                Kuru öksürüğün verdiği rahatsızlığı gidermek ve boğazı
                rahatlatmak için bal tüketilebilir. Bal tüketmek, boğaz ağrısını
                gidermek için tercih edilen, geleneksel bir yöntemdir. Bal kendi
                başına ya da ılık bir içecekle karıştırılarak tüketilebilir.
                Balın 1 yaşından küçük çocuklara verilmesi önerilmez.
                <br />
                <br />
                Öksürüğe yakalanmamak için bazı ipuçlarını bilmek de önemlidir.
                Öksürüğün başlıca nedenleri grip ve soğuk algınlığıdır. Solunum
                yolu enfeksiyonlarına yakalanmamak ve hastalığı başkalarına
                yaymamak için aşağıdaki önlemlerin alınması önerilir:
              </p>
              <ul>
                <li className="blue">
                  Hasta kişilerle yakın temastan kaçınılmalıdır. Eller bol su ve
                  sabunla sık sık yıkanmalıdır. Kişi eğer hasta olduğunu
                  hissediyorsa, kalabalık ortamlara (okul, iş vb.) girmekten
                  kaçınmalıdır.
                </li>
                <li className="blue">
                  Öksürürken ya da hapşırırken, burun ve ağız dirsek içi ile
                  kapatılmalıdır.
                </li>
                <li className="blue">Bol sıvı tüketilmelidir.</li>
                <li className="blue">
                  Bulunulan ortam sık sık havalandırılmalıdır.
                </li>
              </ul>
            </Level>
          </section>
          <section id="bisolnatur" className={styles.bisolnatur}>
            <Level>
              <Heading>
                Kuru Öksürüğe Doğal ve Etkili Öksürük Şurubu - Bisolnatur
              </Heading>
              <div className={classNames("flexbox", styles.productHolder)}>
                <StaticImage
                  className={styles.image}
                  src="../../../images/bisolnatur-urun-yeni.png"
                  alt="Bisolnatur ürün görseli"
                  width={282}
                  height={282}
                />
                <div className={styles.content}>
                  <p>
                    Bisolnatur, doğal içeriğiyle boğazda koruyucu bir tabaka
                    oluşturarak, boğazın tahriş olmasını engeller ve kuru
                    öksürüğü rahatlatır. Aynı zamanda, balgamlı öksürükte de
                    yumuşatarak kolayca atılmasını sağlar.
                  </p>
                </div>
              </div>
              <Level>
                <Heading>Bisolnatur’ün İçeriği</Heading>
                <p>
                  Doğal içerikli Bisolnatur, patentli Poliflav M.A. kompleksi ve
                  bal içerir.
                </p>
                <div className={classNames("flexbox", styles.productHolder)}>
                  <StaticImage
                    className={styles.image}
                    src="../../../images/GettyImages-627904344-Sinirotu.webp"
                    alt="Bisolnatur ürün görseli"
                    width={282}
                    height={188}
                  />
                  <div className={styles.content}>
                    <Level>
                      <Heading>Poliflav M.A. kompleksi</Heading>
                    </Level>
                    <p>
                      Mızrak yapraklı sinirotundan elde edilen polisakkarit
                      fraksiyonu ile kekikten elde edilen flavonoid
                      fraksiyonundan oluşur.
                    </p>
                  </div>
                </div>
                <div className={classNames("flexbox", styles.productHolder)}>
                  <StaticImage
                    className={styles.image}
                    src="../../../images/GettyImages-157394621-Bal.webp"
                    alt="Bisolnatur ürün görseli"
                    width={282}
                    height={188}
                  />
                  <div className={styles.content}>
                    <Level>
                      <Heading>Bal</Heading>
                    </Level>
                    <p>
                      Doğadaki en eski şifa kaynaklarından biri olan bal,
                      solunum yollarında kayganlaştırıcı bir etkiye sahiptir.
                      Öksürüğün sebep olduğu boğaz tahrişini rahatlatır.
                    </p>
                  </div>
                </div>
                <Heading>Bisolnatur’ün Özellikleri</Heading>
                <p>
                  Doğal içeriğiyle öksürüğü rahatlatan Bisolnatur renklendirici,
                  koruyucu, tatlandırıcı, paraben gibi hiçbir katkı maddesi
                  içermez. Aynı zamanda, glüten de içermediği için çölyak
                  hastaları tarafından güvenle kullanılabilir.
                  <br />
                  <br />
                  Bisolnatur içerdiği doğal şeftali, limon ve portakal aromaları
                  ile hoş bir tada sahiptir. T.C Sağlık Bakanlığı TİTCK’na*
                  kayıtlı olan Bisolnatur, 1 yaşından büyük herkes tarafından
                  kullanılabilir. Hamilelik ve emzirme dönemlerinde ise doktora
                  danışılarak kullanılabilir.
                  <br />
                  <br />
                  *Türkiye İlaç Tıbbi Cihaz Kurumu
                </p>
                <Heading>Bisolnatur Nasıl Kullanılır?</Heading>
                <p>
                  Bisolnatur’u kullanmadan önce kullanım kılavuzu dikkatle
                  okunmalıdır. Doğru şekilde kullanım için, şişe açılmadan önce
                  iyice çalkalanmalı ve kutu ile birlikte verilen kaşık
                  kullanılmalıdır.
                  <br />
                  <br />
                  <b>
                    Hamile ve emzirenler doktora danışarak Bisolnatur’ü
                    kullanabilir.
                  </b>
                  <br />
                  <br />
                  Tüketiciler için önerilen kullanım dozları şu şekildedir (bir
                  doz=bir ölçü kaşığı):
                </p>
                <ul>
                  <li className="blue">
                    <b>1-6 yaş arasındaki çocuklarda</b>, 1 ölçü kaşığı (5 ml)
                    olmak üzere günde iki doz
                  </li>
                  <li className="blue">
                    <b>6 yaş ve üzerindeki çocuklarda</b> 2 ölçü kaşığı (10 ml)
                    olmak üzere günde iki doz
                  </li>
                  <li className="blue">
                    <b>Yetişkinlerde</b> ise 2 ölçü kaşığı (10 ml) olmak üzere
                    günde iki doz (Aynı zamanda, yetişkinlerde, tüketicinin
                    gerekli gördüğü durumlarda kısa aralıklarla 4 defaya kadar
                    kullanılması mümkündür.)
                  </li>
                </ul>
                <p>
                  Sağlık Bakanlığı’na kayıtlı olan Bisolnatur’ün 1 yaş üzeri
                  çocuklar için kullanımı uygundur. Yetişkinlerde veya
                  çocuklarda uyku hâline sebep olmaz. Bisolnatur’ün
                  kullanımında, öksürük belirtilerinin bir hafta içinde
                  iyileşmemesi hâlinde doktora veya eczacıya başvurulması
                  önerilir.
                </p>
                <Heading>Bisolnatur Saklama Koşulları</Heading>
                <p>
                  Bisolnatur; serin, kuru ve çocukların ulaşamayacağı bir yerde
                  saklanmalıdır. Direkt olarak güneş ışığına maruz
                  bırakılmamalıdır. Şişesi açıldıktan sonra 3 ay içinde
                  tüketilmelidir.
                </p>
                <Heading id="kaynakca">Kaynakça</Heading>
                <p>
                  https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2080754/
                </p>
                <p>
                  https://www.nhs.uk/conditions/cough/
                </p>
                <p>
                  https://www.medicalnewstoday.com/articles/322394
                </p>
              </Level>
            </Level>
          </section>
        </div>
        <div className="date">
          <p>Yayınlama Tarihi: 2021-09-01</p>
          <p>Son güncellenme Tarihi: 2021-09-01</p>
        </div>
      </Container>
      <ProductBar />
    </Layout>
  );
};
export default CoughHowPage;
